import PropTypes from 'prop-types'
import React from 'react'

import DynamicImage from 'components/DynamicImage'
import { ListItem } from 'components/List'
import { RU, JP } from 'constants/countries'
import { LocalizationContext } from 'contexts/Localization'

import FeatureTitle from '../FeatureTitle'
import { FeatureBox } from '../SectionFeature/styles'
import { ContentBox } from '../styles'

import {
  RowBox,
  ContentBoxAligned,
  ListStyled,
  DownloadLinks,
  DownloadLink,
  StyledImg,
  NutraLogo,
  Wrapper,
} from './styles'

const SectionApp = ({
  data,
  photos,
  children,
  isVisible,
  mobile,
  locale,
  inView,
}) => {
  const {
    paths: { shared },
  } = React.useContext(LocalizationContext)
  const textColor = !mobile ? 'dark' : 'white'
  const bulletColor = !mobile ? 'blue' : 'white'
  const isRussia = locale === RU
  const isJp = locale === JP

  return (
    <FeatureBox isApp={true} isJp={isJp}>
      {children}

      <RowBox className="row">
        <div className="col-12 col-lg-4">
          <ContentBox {...{ isVisible }}>
            <FeatureTitle
              {...{
                title: data.title,
                offMinHeight: true,
              }}
            />
          </ContentBox>
        </div>
        <div className="col-12 offset-lg-5 col-lg-3">
          <ContentBoxAligned {...{ isVisible }}>
            <ListStyled leftAlignment="text" textColor={textColor}>
              {data.list.map(
                (item, id) =>
                  item && (
                    <ListItem key={`mobile.${id}`} bulletColor={bulletColor}>
                      {item}
                    </ListItem>
                  ),
              )}
            </ListStyled>
          </ContentBoxAligned>
          <Wrapper>
            <NutraLogo isJp={isJp}>
              <DynamicImage
                image={`${shared}/nia_logo/nia`}
                imageSizes={{
                  xs: 75,
                  md: 90,
                  lg: 90,
                }}
                namesByBreakpoints={true}
                alt="Winner Nutra Ingredients Awards 2021"
                imageType="png"
              />
            </NutraLogo>
            {!isJp && (
              <DownloadLinks {...{ isVisible }}>
                <DownloadLink
                  className="no-hover"
                  href={data.ios_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  isRussia={isRussia}
                >
                  {inView && (
                    <StyledImg src={photos.downloadSrc.ios} alt="app-store" />
                  )}
                </DownloadLink>
                <DownloadLink
                  className="no-hover"
                  href={data.android_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {inView && (
                    <StyledImg
                      src={photos.downloadSrc.android}
                      alt="play-market"
                    />
                  )}
                </DownloadLink>
              </DownloadLinks>
            )}
          </Wrapper>
        </div>
      </RowBox>
    </FeatureBox>
  )
}

SectionApp.propTypes = {
  data: PropTypes.object.isRequired,
  mobile: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  inView: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool,
  children: PropTypes.node,
  photos: PropTypes.object,
}

SectionApp.defaultProps = {
  isVisible: true,
  contentClassName: '',
  contentWrapperClassName: '',
  photos: {},
}

export default SectionApp
