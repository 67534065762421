import PropTypes from 'prop-types'
import React from 'react'

import { Box, H2Styled } from './style'

const FeatureTitle = ({ title, offMinHeight, className }) => {
  return (
    <Box className={className}>
      <H2Styled
        {...{ offMinHeight }}
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </Box>
  )
}

FeatureTitle.propTypes = {
  title: PropTypes.string.isRequired,
  offMinHeight: PropTypes.bool,
  className: PropTypes.string,
}

FeatureTitle.defaultProps = {
  offMinHeight: false,
}

export default FeatureTitle
