import PropTypes from 'prop-types'
import React from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'

import DynamicImage from 'components/DynamicImage'

import { Borders, Screen, PictureBox, PictureAnimation } from './styles'

const MorphIpad = React.forwardRef(
  (
    {
      photos,
      isIPhone,
      innerRef,
      imageBoxRef,
      appPhoto,
      appRef,
      title,
      inView,
      breakpoint,
    },
    ref,
  ) => {
    return (
      <Borders {...{ isIPhone, ref, inView }}>
        <Screen {...{ isIPhone, ref: innerRef, inView }}>
          <PictureBox {...{ ref: imageBoxRef }}>
            {photos && breakpoint && photos !== appPhoto && (
              <TransitionGroup component={null}>
                <Transition timeout={300} key={photos.src}>
                  {status => (
                    <PictureAnimation status={status}>
                      {inView && (
                        <DynamicImage
                          imageSizes={{ xs: 280, md: 600 }}
                          image={photos.src}
                          alt={title}
                        />
                      )}
                    </PictureAnimation>
                  )}
                </Transition>
              </TransitionGroup>
            )}
          </PictureBox>
          {appRef && (
            <PictureBox {...{ ref: appRef }}>
              {breakpoint && appPhoto === photos && inView && (
                <DynamicImage
                  imageSizes={{ md: 188 }}
                  image={photos.src}
                  alt={title}
                />
              )}
            </PictureBox>
          )}
        </Screen>
      </Borders>
    )
  },
)

MorphIpad.displayName = 'MorphIpad'

MorphIpad.propTypes = {
  breakpoint: PropTypes.string.isRequired,
  inView: PropTypes.bool,
  photos: PropTypes.object,
  appPhoto: PropTypes.object,
  innerRef: PropTypes.object,
  imageBoxRef: PropTypes.object,
  appRef: PropTypes.object,
  isIPhone: PropTypes.bool,
  title: PropTypes.string,
}

MorphIpad.defaultProps = {
  photos: {},
  isIPhone: false,
  title: '',
}

export default MorphIpad
