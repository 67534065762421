import { em } from 'polished'
import styled, { css } from 'styled-components'

export const ContentBox = styled.div`
  max-width: 100%;
  transition: opacity ${p => p.theme.animation.timeBtns} ease-in;
  width: ${p => (p.language === 'de' ? em(500) : em(460))};
  margin: 0 auto;
  ${p =>
    p.language === 'de' &&
    css`
      word-break: break-word;
    `}

  ${p => p.theme.mq.upMd} {
    width: ${em(500)};
  }

  ${p => p.theme.mq.upLg} {
    width: 100%;
    opacity: ${p => (p.isVisible ? 1 : 0)};
  }
`
