import { em } from 'polished'
import styled from 'styled-components'

import List from 'components/List'

import { ContentBox } from '../styles'

export const RowBox = styled.div`
  ${p => p.theme.mq.upLg} {
    height: 100%;
    align-items: center;
  }
`

export const ListStyled = styled(List)`
  display: inline-block;
  text-align: left;

  ${p => p.theme.mq.upMd} {
    position: relative;
  }
`

export const ContentBoxAligned = styled(ContentBox)`
  text-align: center;

  ${p => p.theme.mq.upLg} {
    text-align: left;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${em(20)};
  align-items: center;

  ${p => p.theme.mq.upMd} {
    padding-top: ${em(20)};
  }

  ${p => p.theme.mq.upLg} {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-top: ${em(50)};
  }
`

export const DownloadLinks = styled.div`
  text-align: center;
  margin: 0 auto;
  transition: opacity ${p => p.theme.animation.timeBtns} ease-in;

  ${p => p.theme.mq.upLg} {
    margin: 0;
    text-align: left;
    opacity: ${p => (p.isVisible ? 1 : 0)};
  }
`

export const DownloadLink = styled.a`
  display: inline-block;
  width: ${em(120)};
  height: ${em(36)};

  &:not(:last-child) {
    margin-right: ${em(10)};
  }
`

export const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`

export const NutraLogo = styled.div`
  width: ${em(75)};
  margin-bottom: ${em(35)};

  ${p => p.theme.mq.upLg} {
    width: ${em(90)};
    margin-top: ${({ isJp }) => (isJp ? 0 : em(30))};
    margin-bottom: 0;
  }
`
