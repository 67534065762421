import { em } from 'polished'
import styled from 'styled-components'

import { BoxBase } from 'styles/components/layout'

export const FeatureBox = styled(BoxBase)`
  display: ${p => (p.isApp ? 'flex' : 'block')};
  flex-direction: column;
  position: relative;
  height: ${p => (p.isApp ? 'auto' : em(470))};
  overflow: ${p => (p.isApp ? 'visible' : 'hidden')};

  ${p => p.theme.mq.upMd} {
    display: block;
    height: ${p => (p.isApp ? (p.isJp ? em(976) : em(1056)) : em(789))};
  }

  ${p => p.theme.mq.upLg} {
    height: ${em(648)};
  }
`

export const TextBox = styled.div`
  text-align: center;
  min-height: ${p => p.theme.typography.baseLh * 3}em;

  ${p => p.theme.mq.upMd} {
    min-height: auto;
  }

  ${p => p.theme.mq.upLg} {
    text-align: left;
  }
`

export const IpadWrapper = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: ${em(20)};

  ${p => p.theme.mq.upMd} {
    display: block;
    padding: ${em(40)} 0;
  }

  ${p => p.theme.mq.upLg} {
    display: none;
  }
`

export const ColBox = styled.div`
  display: flex;
  flex-direction: column;

  ${p => p.theme.mq.upMd} {
    display: block;
  }
`

export const FlexPlug = styled.div`
  flex-grow: 2;
`
