import PropTypes from 'prop-types'
import React from 'react'

import { LocalizationContext } from 'contexts/Localization'

import confeti from './assets/confeti.svg'
import {
  Box,
  ConfetiImg,
  DynamicImageHand,
  DynamicImageFingers,
} from './styles'

const HandPic = ({ children, confetiVisible, breakpoint }) => {
  const {
    paths: { shared },
  } = React.useContext(LocalizationContext)
  return (
    <Box>
      {breakpoint && (
        <DynamicImageHand
          image={`${shared}/hand/hand`}
          imageSizes={{
            md: 625,
          }}
          alt="hand"
          imageType="png"
        />
      )}
      {breakpoint && (
        <ConfetiImg
          alt=""
          draggable="false"
          src={confeti}
          isVisible={confetiVisible}
        />
      )}
      {children}
      {breakpoint && (
        <DynamicImageFingers
          image={`${shared}/hand/fingers`}
          imageSizes={{
            md: 82,
          }}
          alt="fingers"
          imageType="png"
        />
      )}
    </Box>
  )
}

HandPic.propTypes = {
  children: PropTypes.node.isRequired,
  breakpoint: PropTypes.string.isRequired,
  confetiVisible: PropTypes.bool,
}

HandPic.defaultProps = {
  confetiVisible: true,
}

export default HandPic
