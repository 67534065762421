import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import BackgroundChart from '../BackgroundChart'
import HandPic from '../HandPic'
import MorphIpad from '../MorphIpad'
import SectionApp from '../SectionApp'
import SectionFeature from '../SectionFeature'

import {
  morphAnimation,
  iconsRecomsAnimationShow,
  iconsAncestryAnimationShow,
  iconsAnimationHide,
} from './animations'
import { Box, IconBox } from './styles'

const KEYS = ['hcc', 'rm', 'fr', 'uh', 'ma']
const KEYS_FULL = {
  [KEYS[0]]: 'health_control',
  [KEYS[1]]: 'risk_management',
  [KEYS[2]]: 'food_recommendations',
  [KEYS[3]]: 'unique_history',
  [KEYS[4]]: 'mobile_app',
}

const FEATURES_PROPS = {
  [KEYS[0]]: {
    className: 'pb-4',
    box: {
      bg: 'lilacDark',
      isTextWhite: true,
    },
    titleAsH1: true,
  },
  [KEYS[1]]: {
    className: 'py-2',
    box: {},
    backgroundChart: true,
  },
  [KEYS[2]]: {
    className: 'py-6',
    box: {
      bg: 'catskillWhite',
      bgPosition: 'right',
    },
    icons: {
      names: 'iconsRecoms',
      refs: 'iconRecomsRefs',
    },
  },
  [KEYS[3]]: {
    className: 'py-6',
    box: {
      bg: 'grayOp06NonTransp',
      bgPosition: 'left',
    },
    icons: {
      names: 'iconsAncestry',
      refs: 'iconAncestryRefs',
    },
  },
}

let ScrollMagic

class SFeaturesDesktop extends Component {
  constructor(props) {
    super(props)

    this.sceneScroll = null
    this.scenesImages = []
    this.sceneMorphing = null
    this.sceneIconsRecoms = null
    this.sceneIconsAncestry = null

    this.features = props.specialDistribution
      ? ['hcc', 'fr', 'uh', 'ma']
      : ['hcc', 'rm', 'fr', 'uh', 'ma']
    this.featuresRef = React.createRef()
    this.featureRefs = this.features.map(() => React.createRef())
    this.iconRecomsRefs = [...Array(3)].map(() => React.createRef())
    this.iconAncestryRefs = [...Array(2)].map(() => React.createRef())
    this.iPadRef = React.createRef()
    this.iPadInnerRef = React.createRef()
    this.imageBoxRef = React.createRef()
    this.appRef = React.createRef()

    this.featuresHeights = []
    this.mainMenuHeight = 0

    this.controller = null
    this.morphTimeline = null
    this.iconsRecomsTimeline = null
    this.iconsAncestryTimeline = null
    this.duration = 0

    this.debouncedResizeHandler = debounce(this.handleResize, 200)

    this.iconsRecoms = ['color_carrot', 'color_milk', 'color_apple_rotated']
    this.iconsAncestry = ['color_man', 'color_blue_pointer']

    this.state = {
      active: 0,
      confetiVisible: false,
    }
  }

  componentDidMount() {
    const { breakpoint } = this.props

    if (breakpoint) {
      this.initController()
    }
  }

  componentDidUpdate(prevProps) {
    const { breakpoint, menuHeight } = this.props

    if (!this.controller && breakpoint) {
      this.initController()
    }

    if (this.controller && menuHeight && menuHeight !== prevProps.menuHeight) {
      this.handleResize()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedResizeHandler)

    if (this.controller) {
      this.controller.destroy()
    }

    if (this.morphTimeline) {
      this.morphTimeline.kill()
      this.morphTimeline = null
    }
    if (this.iconsRecomsTimeline) {
      this.iconsRecomsTimeline.kill()
      this.iconsRecomsTimeline = null
    }
    if (this.iconsAncestryTimeline) {
      this.iconsAncestryTimeline.kill()
      this.iconsAncestryTimeline = null
    }
  }

  async initController() {
    if (!ScrollMagic) {
      // eslint-disable-next-line
      ScrollMagic = (await import('ScrollMagic')).default
    }
    require('animation.gsap')

    this.controller = new ScrollMagic.Controller()
    window.addEventListener('resize', this.debouncedResizeHandler)
    this.initHand()
  }

  initHand() {
    if (this.featuresRef.current) {
      this.updateMenuHeight()
      this.calculateDurationForScene()
      // there are some troubles here for development mode:
      // when you reload page inside sections with ipad
      // position of ipad will not calculate correctly
      // because of heights equal to 0 for "Feature sections"
      // This behaviour do not repeat for production
      // because of each section has height for this moment rather than 0
      this.updateIPadScrollStyles(0)
      this.initScrollScene()
      this.initMorphScene()
      this.initImagesScenes()
      this.initIconsRecomsScene()
      this.initIconsAncestryScene()
    }
  }

  updateMenuHeight() {
    if (this.props.menuNode) {
      this.mainMenuHeight = this.props.menuNode.getBoundingClientRect().height
    }
  }

  initScrollScene() {
    this.sceneScroll = new ScrollMagic.Scene({
      triggerElement: this.featuresRef.current,
      duration: this.duration,
      triggerHook: 0.5,
      offset: this.featuresHeights[0] / 2 - this.mainMenuHeight,
    })
      .on('enter', () => {
        if (this.mainMenuHeight) {
          this.updateIPadScrollStyles(0.5)
        }
      })
      .on('leave', event => {
        const progress = event.scrollDirection === 'FORWARD' ? 1 : 0
        this.updateIPadScrollStyles(progress)
      })
      .on('progress', () => {
        if (this.state.confetiVisible) {
          this.setState(() => ({ confetiVisible: false }))
        }
      })
      .on('change', event => {
        if (event.what === 'offset' || event.what === 'duration') {
          this.updateIPadScrollStyles(event.target.progress())
        }
      })
      .on('end', () => {
        if (this.featureRefs.current)
          this.setState({
            confetiVisible: true,
          })
      })
      .addTo(this.controller)
  }

  updateScrollScene = () => {
    if (this.sceneScroll && this.controller) {
      this.sceneScroll.duration(this.duration)
      this.sceneScroll.offset(this.featuresHeights[0] / 2 - this.mainMenuHeight)
      this.sceneScroll.refresh()
    }
  }

  initMorphScene() {
    const beforeLastIndex = this.featureRefs.length - 2
    const beforeLastBoxHeight = this.featuresHeights[beforeLastIndex]
    const sceneDuration = beforeLastBoxHeight / 2
    const offset = (beforeLastBoxHeight / 7) * 5

    this.morphTimeline = morphAnimation({
      imageBox: this.imageBoxRef,
      appRef: this.appRef,
      outerIpad: this.iPadRef,
      innerIpad: this.iPadInnerRef,
    })

    this.sceneMorphing = new ScrollMagic.Scene({
      triggerElement: this.featureRefs[beforeLastIndex].current,
      duration: sceneDuration,
      offset,
    })
      .setTween(this.morphTimeline)
      .addTo(this.controller)
  }

  updateMorphScene = () => {
    if (this.sceneMorphing && this.controller) {
      const beforeLastIndex = this.featureRefs.length - 2
      const beforeLastBoxHeight = this.featuresHeights[beforeLastIndex]
      const sceneDuration = beforeLastBoxHeight / 2
      const offset = (beforeLastBoxHeight / 7) * 5

      this.sceneMorphing.duration(sceneDuration)
      this.sceneMorphing.offset(offset)
      this.sceneMorphing.refresh()
    }
  }

  initImagesScenes() {
    this.featureRefs.forEach((ref, index) => {
      const scene = new ScrollMagic.Scene({
        triggerElement: ref.current,
        duration: this.featuresHeights[index],
        triggerHook: 0.5,
      })
        .on('enter', () => {
          if (ref.current)
            this.setState({
              active: index,
            })
        })
        .addTo(this.controller)

      this.scenesImages.push(scene)
    })
  }

  updateImagesScenes = () => {
    if (this.scenesImages.length && this.controller) {
      this.featureRefs.forEach((ref, index) => {
        const height = this.featuresHeights[index]

        this.scenesImages[index].duration(height)
        this.scenesImages[index].refresh()
      })
    }
  }

  initIconsRecomsScene() {
    const idx = this.features.indexOf(KEYS[2])
    if (idx !== -1) {
      this.sceneIconsRecoms = new ScrollMagic.Scene({
        triggerElement: this.featureRefs[idx].current,
        duration: this.featuresHeights[idx] / 2,
        triggerHook: 0.5,
        offset: this.featuresHeights[idx] / 3,
      })
        .on('enter', event => {
          if (event.scrollDirection === 'FORWARD') {
            if (this.iconsRecomsTimeline) {
              this.iconsRecomsTimeline.kill()
            }
            this.iconsRecomsTimeline = iconsRecomsAnimationShow({
              iconsRefs: this.iconRecomsRefs,
            })
          }
        })
        .on('leave', event => {
          if (event.scrollDirection === 'REVERSE') {
            if (this.iconsRecomsTimeline) {
              this.iconsRecomsTimeline.kill()
            }
            this.iconsRecomsTimeline = iconsAnimationHide({
              iconsRefs: this.iconRecomsRefs,
            })
          }
        })
        .addTo(this.controller)
    }
  }

  updateIconsRecomsScene = () => {
    const idx = this.features.indexOf(KEYS[2])
    if (this.sceneIconsRecoms && this.controller) {
      this.sceneIconsRecoms.duration(this.featuresHeights[idx] / 2)
      this.sceneIconsRecoms.offset(this.featuresHeights[idx] / 3)
      this.sceneIconsRecoms.refresh()
    }
  }

  initIconsAncestryScene() {
    const idx = this.features.indexOf(KEYS[3])
    if (idx !== -1) {
      this.sceneIconsAncestry = new ScrollMagic.Scene({
        triggerElement: this.featureRefs[idx].current,
        duration: this.featuresHeights[idx] / 2,
        triggerHook: 0.5,
        offset: this.featuresHeights[idx] / 3,
      })
        .on('enter', event => {
          if (event.scrollDirection === 'FORWARD') {
            if (this.iconsAncestryTimeline) {
              this.iconsAncestryTimeline.kill()
            }
            this.iconsAncestryTimeline = iconsAncestryAnimationShow({
              iconsRefs: this.iconAncestryRefs,
            })
          }
        })
        .on('leave', event => {
          if (event.scrollDirection === 'REVERSE') {
            if (this.iconsAncestryTimeline) {
              this.iconsAncestryTimeline.kill()
            }
            this.iconsAncestryTimeline = iconsAnimationHide({
              iconsRefs: this.iconAncestryRefs,
            })
          }
        })
        .addTo(this.controller)
    }
  }

  updateIconsAncestryScene = () => {
    const idx = this.features.indexOf(KEYS[3])
    if (this.sceneIconsAncestry && this.controller) {
      this.sceneIconsAncestry.duration(this.featuresHeights[idx] / 2)
      this.sceneIconsAncestry.offset(this.featuresHeights[idx] / 3)
      this.sceneIconsAncestry.refresh()
    }
  }

  calculateDurationForScene() {
    this.duration = 0

    this.featureRefs.forEach((featureRef, index) => {
      const {
        height: featureHeight,
      } = featureRef.current.getBoundingClientRect()

      this.featuresHeights[index] = featureHeight

      if (index === this.featureRefs.length - 1) {
        this.duration += 0
      } else {
        this.duration += featureHeight
      }
    })
  }

  updateIPadScrollStyles(progress) {
    if (!this.iPadRef.current) return

    if (progress > 0 && progress < 1) {
      this.iPadRef.current.style.setProperty('position', `fixed`)
      this.iPadRef.current.style.setProperty(
        'top',
        `calc(50% + ${this.mainMenuHeight}px/2)`,
      )
      this.iPadRef.current.style.setProperty('transform', `translateY(-50%)`)
    } else {
      const translateY = progress === 0 ? -this.duration : 0

      this.iPadRef.current.style.setProperty('position', `absolute`)
      this.iPadRef.current.style.setProperty('top', `0`)
      this.iPadRef.current.style.setProperty(
        'transform',
        `translateY(${translateY}px)`,
      )
    }
  }

  handleResize = () => {
    if (this.featuresRef.current) {
      this.updateMenuHeight()
      this.calculateDurationForScene()
      this.updateScrollScene()
      this.updateMorphScene()
      this.updateImagesScenes()
      this.updateIconsRecomsScene()
      this.updateIconsAncestryScene()
    }
  }

  render() {
    const { data, photos, inView, breakpoint, mobile, locale } = this.props
    const { active, confetiVisible } = this.state
    const activeKey = this.features[active]
    return (
      <div ref={this.featuresRef}>
        {this.features.map((code, idx) =>
          code !== 'ma' ? (
            <div
              key={code}
              className={FEATURES_PROPS[code].className}
              ref={this.featureRefs[idx]}
            >
              <Box {...FEATURES_PROPS[code].box}>
                {FEATURES_PROPS[code].backgroundChart && <BackgroundChart />}
                <SectionFeature
                  titleAsH1={!!FEATURES_PROPS[code].titleAsH1}
                  data={data[KEYS_FULL[code]]}
                  isVisible={activeKey === code}
                />
                {!!FEATURES_PROPS[code].icons &&
                  this[FEATURES_PROPS[code].icons.names].map((id, index) => (
                    <IconBox
                      key={id}
                      {...{
                        id,
                        wrapperRef: this[FEATURES_PROPS[code].icons.refs][
                          index
                        ],
                      }}
                    />
                  ))}
              </Box>
            </div>
          ) : (
            <div
              key={code}
              className="position-relative"
              ref={this.featureRefs[idx]}
            >
              <SectionApp
                {...{
                  data: data.mobile_app,
                  photos: photos[KEYS[KEYS.length - 1]],
                  isVisible: activeKey === code,
                  inView,
                  mobile,
                  locale,
                }}
              >
                <HandPic {...{ confetiVisible, breakpoint }}>
                  <MorphIpad
                    {...{
                      breakpoint,
                      inView,
                      photos: photos[activeKey],
                      appPhoto: photos[KEYS[KEYS.length - 1]],
                      ref: this.iPadRef,
                      appRef: this.appRef,
                      innerRef: this.iPadInnerRef,
                      imageBoxRef: this.imageBoxRef,
                      title: data[KEYS_FULL[activeKey]].title,
                    }}
                  />
                </HandPic>
              </SectionApp>
            </div>
          ),
        )}
      </div>
    )
  }
}

SFeaturesDesktop.propTypes = {
  data: PropTypes.object.isRequired,
  photos: PropTypes.object.isRequired,
  mobile: PropTypes.bool.isRequired,
  breakpoint: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  specialDistribution: PropTypes.bool.isRequired,
  inView: PropTypes.bool,
  menuNode: PropTypes.object,
  menuHeight: PropTypes.number,
}

export default SFeaturesDesktop
