import React from 'react'

import { Box } from './styles'

const BackgroundChart = () => (
  <Box>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1209.3 469.6"
      enableBackground="new 0 0 1209.3 469.6"
    >
      <polyline
        fill="none"
        stroke="#E6E6E6"
        strokeMiterlimit="10"
        points="1182.6,469.4 1092.9,250.5 802.9,250.5 472.8,8.9 322.9,128.9 197.9,128.9 31.6,22.2 0.3,2.2 "
      />
      <polyline
        fill="none"
        stroke="#E6E6E6"
        strokeMiterlimit="10"
        points="1182.5,186.9 1090.8,102.4 905.2,72.4 730.8,18.1 472.8,190.8 322.2,190.8 193.9,190.8 30.9,225.2 0.3,225.2 "
      />
      <circle fill="#FF827C" cx="31.6" cy="22.2" r="8.9" />
      <circle
        fill="#FFFFFF"
        stroke="#E6E6E6"
        strokeMiterlimit="10"
        cx="195"
        cy="128.2"
        r="8.9"
      />
      <circle
        fill="#FFFFFF"
        stroke="#E6E6E6"
        strokeMiterlimit="10"
        cx="325.6"
        cy="128.2"
        r="8.9"
      />
      <circle fill="#FF827C" cx="472.8" cy="8.9" r="8.9" />
      <circle
        fill="#FFFFFF"
        stroke="#E6E6E6"
        strokeMiterlimit="10"
        cx="1094"
        cy="251.3"
        r="8.9"
      />
      <circle
        fill="#FFFFFF"
        stroke="#E6E6E6"
        strokeMiterlimit="10"
        cx="31.6"
        cy="225.2"
        r="8.9"
      />
      <circle
        fill="#FFFFFF"
        stroke="#E6E6E6"
        strokeMiterlimit="10"
        cx="195"
        cy="190.9"
        r="8.9"
      />
      <circle
        fill="#FFFFFF"
        stroke="#E6E6E6"
        strokeMiterlimit="10"
        cx="325.6"
        cy="190.9"
        r="8.9"
      />
      <circle
        fill="#FFFFFF"
        stroke="#E6E6E6"
        strokeMiterlimit="10"
        cx="472.8"
        cy="190.9"
        r="8.9"
      />
      <circle fill="#FF827C" cx="730.8" cy="18.1" r="8.9" />
      <circle
        fill="#FFFFFF"
        stroke="#E6E6E6"
        strokeMiterlimit="10"
        cx="905.5"
        cy="73"
        r="8.9"
      />
      <circle
        fill="#FFFFFF"
        stroke="#E6E6E6"
        strokeMiterlimit="10"
        cx="803.5"
        cy="250"
        r="8.9"
      />
      <circle
        fill="#FFFFFF"
        stroke="#E6E6E6"
        strokeMiterlimit="10"
        cx="1095"
        cy="103"
        r="8.9"
      />
      <g>
        <line
          fill="none"
          stroke="#E6E6E6"
          strokeMiterlimit="10"
          x1="1182.5"
          y1="469.2"
          x2="1182.5"
          y2="412.5"
        />
        <polyline
          fill="#6ADD64"
          points="1182.6,412.7 1209.1,412.7 1199.8,422 1209.3,431.6 1182.6,431.9"
        />
      </g>
      <g>
        <line
          fill="none"
          stroke="#E6E6E6"
          strokeMiterlimit="10"
          x1="1182.5"
          y1="186.9"
          x2="1182.5"
          y2="130.2"
        />
        <polyline
          fill="#6ADD64"
          points="1182.5,130.2 1209.1,130.2 1199.7,139.5 1209.3,149.1 1182.5,149.4"
        />
      </g>
      <line
        opacity="0.1"
        fill="none"
        stroke="#000000"
        strokeMiterlimit="10"
        strokeDasharray="12"
        enableBackground="new"
        x1="0.3"
        y1="46.1"
        x2="1180.6"
        y2="46.1"
      />
    </svg>
  </Box>
)

export default BackgroundChart
