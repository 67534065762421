import PropTypes from 'prop-types'
import React from 'react'

import { Text } from 'styles/components/typography'

import FeatureTitle from '../FeatureTitle'
import MorphIpad from '../MorphIpad'
import { ContentBox } from '../styles'

import { FeatureBox, ColBox, FlexPlug, TextBox, IpadWrapper } from './styles'

const SectionFeature = ({ data, photos, isVisible, breakpoint }) => {
  return (
    <FeatureBox as="section">
      <div className="row h-100 align-items-lg-center">
        <ColBox className="col-12 col-lg-5">
          <ContentBox {...{ isVisible }}>
            <FeatureTitle
              {...{
                title: data.title,
              }}
            />
            <TextBox>
              <Text dangerouslySetInnerHTML={{ __html: data.description }} />
            </TextBox>
          </ContentBox>
          {photos && (
            <IpadWrapper>
              <FlexPlug />
              <MorphIpad
                {...{
                  photos,
                  title: data.title,
                  inView: isVisible,
                  breakpoint,
                }}
              />
            </IpadWrapper>
          )}
        </ColBox>
      </div>
    </FeatureBox>
  )
}

SectionFeature.propTypes = {
  data: PropTypes.object.isRequired,
  photos: PropTypes.object,
  isVisible: PropTypes.bool,
  breakpoint: PropTypes.string,
}

export default SectionFeature
