import { em } from 'polished'
import styled from 'styled-components'

import { H2 } from 'styles/components/typography'

export const Box = styled.div`
  text-align: center;
  padding-top: ${em(35)};

  ${p => p.theme.mq.upMd} {
    padding-top: ${em(60)};
  }

  ${p => p.theme.mq.upLg} {
    text-align: left;
    padding-top: 0;
  }
`

export const H2Styled = styled(H2)`
  display: inline-block;
`
