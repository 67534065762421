import { em } from 'polished'
import styled, { css } from 'styled-components'

const IPhoneBordersMd = css`
  position: absolute;
  top: ${em(30)};
  left: ${em(200)};
  width: ${em(209)};
  height: ${em(425)};
  padding: ${em(10)};
`

const IPadBordersMd = css`
  width: ${em(561)};
  height: ${em(404)};
  border-radius: ${em(22)};
  padding: ${em(20)} ${em(37)};
`

export const Borders = styled.div`
  background: ${p => p.theme.colors.white};
  max-width: ${em(330)};
  width: 100%;
  height: auto;
  position: relative;
  margin: 0 auto ${em(-20)};
  border-radius: ${em(20)};
  padding: ${em(18)} ${em(22)};
  box-shadow: ${em(12)} ${em(4)} ${em(26)} ${em(5)} rgba(0, 0, 0, 0.1);
  user-select: none;

  ${p => p.theme.mq.upMd} {
    max-width: none;
    margin: 0 auto;
    ${p => (p.isIPhone ? IPhoneBordersMd : IPadBordersMd)}
  }

  ${p => p.theme.mq.upLg} {
    position: absolute;
    margin-left: ${em(200)};
    will-change: ${p =>
      p.inView ? 'position, top, transform, width' : 'auto'};
  }
`

export const Screen = styled.div`
  width: 100%;
  height: auto;
  min-height: ${em(175)};
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  border: 1px solid ${p => p.theme.colors.blackOp1};

  ${p => p.theme.mq.upMd} {
    height: 100%;
    min-height: auto;
    border-radius: ${p => (p.isIPhone ? em(15) : 0)};
    will-change: ${p => (p.inView ? 'border-radius' : 'auto')};
  }
`

export const PictureBox = styled.div`
  overflow: hidden;
  width: inherit;
`

export const PictureAnimation = styled.div`
  width: 100%;
  transition: opacity ${p => p.theme.animation.timeStandard} ease-in;

  ${p => {
    switch (p.status) {
      case 'exiting':
      case 'exited':
        return css`
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        `
      case 'entering':
      case 'entered':
      default:
        return css`
          position: relative;
          opacity: 1;
        `
    }
  }}
`
