import styled from 'styled-components'

export const Box = styled.div`
  position: absolute;
  top: 10%;
  left: -60%;
  right: 5%;
  bottom: 0;

  ${p => p.theme.mq.upLg} {
    left: 0;
  }
`
