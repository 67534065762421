import { em } from 'polished'
import styled, { css } from 'styled-components'

import Icon from 'components/Icon'

const bgBefore = css`
  ${p => p.theme.mq.upLg} {
    background: none;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      ${p =>
        p.bgPosition === 'left'
          ? css`
              left: 0;
              width: 53%;
            `
          : css`
              right: 0;
              width: 39%;
            `};
      background: ${({ bg, theme: { colors } }) =>
        bg ? colors[bg] || 'none' : 'none'};
    }
  }
`

export const Box = styled.div`
  overflow: hidden;
  background: ${({ bg, theme: { colors } }) =>
    bg ? colors[bg] || colors.white : colors.white};
  color: ${p => (p.isTextWhite ? p.theme.colors.white : 'inherit')};
  position: relative;

  ${p => (p.bgPosition === 'left' || p.bgPosition === 'right') && bgBefore}
`

export const IconBox = styled(Icon)`
  position: absolute;
  z-index: 450;
  opacity: 0;
  top: 40%;
  right: 20%;

  ${p => {
    switch (p.id) {
      case 'color_carrot':
        return css`
          width: ${em(124)};
          height: ${em(114)};
        `
      case 'color_milk':
        return css`
          width: ${em(116)};
          height: ${em(115)};
        `
      case 'color_apple_rotated':
        return css`
          width: ${em(101)};
          height: ${em(80)};
        `
      case 'color_man':
        return css`
          width: ${em(132)};
          height: ${em(98)};
        `
      case 'color_blue_pointer':
      default:
        return css`
          width: ${em(87)};
          height: ${em(104)};
        `
    }
  }}
`
