import { em, math } from 'polished'
import styled from 'styled-components'

import DynamicImage from 'components/DynamicImage'

const handHeight = em(548)
const handWidth = em(625)
const handOffset = math(`${handWidth} / 4`)

export const Box = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  user-select: none;
  pointer-events: none;

  ${p => p.theme.mq.upLg} {
    transform: none;
    left: 55%;
    margin-left: -${math(`${handWidth} / 2`)};
  }
`

export const DynamicImageHand = styled(DynamicImage)`
  position: relative;
  width: ${handWidth};
  height: ${handHeight};
  left: -${handOffset};
`

export const DynamicImageFingers = styled(DynamicImage)`
  position: absolute;
  top: ${em(175)};
  left: ${em(387)};
  width: ${em(82)};
  height: auto;
`

export const ConfetiImg = styled.img`
  position: absolute;
  top: ${em(-60)};
  left: 0;
  width: ${handWidth};
  height: ${handHeight};
  transition: opacity ${p => p.theme.animation.timeStandard} ease-in;
  opacity: ${p => (p.isVisible ? 1 : 0)};

  ${p => p.theme.mq.upLg} {
    top: ${em(-200)};
  }
`
