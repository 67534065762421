import { em } from 'polished'

import CSSPlugin from 'gsapAlias/CSSPlugin'
import TimelineLite from 'gsapAlias/TimelineLite'
import TweenLite from 'gsapAlias/TweenLite'

// eslint-disable-next-line no-unused-vars
const plugins = [CSSPlugin]

const MOBILE_PADDING = em(10)
const IPAD_PADDING_Y = em(21)
const IPAD_PADDING_X = em(35)
const IPAD = {
  width: em(580),
  height: em(425),
  paddingTop: IPAD_PADDING_Y,
  paddingBottom: IPAD_PADDING_Y,
  paddingLeft: IPAD_PADDING_X,
  paddingRight: IPAD_PADDING_X,
  innerBorderR: em(0),
}
const IPHONE = {
  width: em(209),
  height: em(425),
  paddingTop: MOBILE_PADDING,
  paddingBottom: MOBILE_PADDING,
  paddingLeft: MOBILE_PADDING,
  paddingRight: MOBILE_PADDING,
  innerBorderR: em(15),
}

export const morphAnimation = ({ imageBox, outerIpad, innerIpad, appRef }) => {
  const timeline = new TimelineLite()
  const { innerBorderR: innerBorderRFrom, ...outerFrom } = IPAD
  const { innerBorderR: innerBorderRTo, ...outerTo } = IPHONE

  timeline.add([
    TweenLite.set(imageBox.current, { alpha: 1, display: 'block' }),
    TweenLite.set(appRef.current, { alpha: 0, display: 'none' }),
    TweenLite.set(outerIpad.current, outerFrom),
    TweenLite.set(innerIpad.current, { borderRadius: innerBorderRFrom }),
    TweenLite.to(imageBox.current, 0.3, { alpha: 0, display: 'none' }),
    TweenLite.to(outerIpad.current, 0.4, outerTo).delay(0.3),
    TweenLite.to(innerIpad.current, 0.4, {
      borderRadius: innerBorderRTo,
    }).delay(0.3),
    TweenLite.to(appRef.current, 0.3, { alpha: 1, display: 'block' }).delay(
      0.7,
    ),
  ])

  return timeline
}

export const iconsRecomsAnimationShow = ({ iconsRefs }) => {
  const timeline = new TimelineLite()
  const baseState = {
    alpha: 0,
    top: '40%',
    right: '20%',
    transform: `translate(0%, 0%)`,
  }

  timeline.add([
    TweenLite.fromTo(iconsRefs[0].current, 0.4, baseState, {
      alpha: 1,
      transform: `translate(132.4%, -172.39%)`,
    }),
    TweenLite.fromTo(iconsRefs[1].current, 0.4, baseState, {
      alpha: 1,
      transform: `translate(178.22%, 24.15%)`,
    }),
    TweenLite.fromTo(iconsRefs[2].current, 0.4, baseState, {
      alpha: 1,
      transform: `translate(164.89%, 280.28%)`,
    }),
  ])

  return timeline
}

export const iconsAncestryAnimationShow = ({ iconsRefs }) => {
  const timeline = new TimelineLite()
  const baseState = {
    alpha: 0,
    top: '40%',
    right: '20%',
    transform: `translate(0%, 0%)`,
  }

  timeline.add([
    TweenLite.fromTo(iconsRefs[0].current, 0.4, baseState, {
      alpha: 1,
      transform: `translate(135.07%, -99.1%)`,
    }),
    TweenLite.fromTo(iconsRefs[1].current, 0.4, baseState, {
      alpha: 1,
      transform: `translate(187.17%, 136.97%)`,
    }),
  ])

  return timeline
}

export const iconsAnimationHide = ({ iconsRefs }) => {
  const timeline = new TimelineLite()
  const baseState = {
    alpha: 1,
  }
  const endState = {
    alpha: 0,
  }
  const tweens = iconsRefs.map(ref =>
    TweenLite.fromTo(ref.current, 0.4, baseState, endState),
  )

  timeline.add(tweens)

  return timeline
}
